import React from 'react';
import Terms from './terms';

const TermsPage = () => {
  return (
    <div className="TermsPage">
      <Terms />
    </div>
  );
};

export default TermsPage;
